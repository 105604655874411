<template>
    <div class="main-limiter margin-bottom-5">
        <article class="main">
            <h1>Artificial Intelligence in Trading and Retail</h1>
            <DoroImage :src="ImageMain" max-width="300px" square></DoroImage>

            <p>How to profit from Artificial Intelligence in the trading and retail industries? Dynamic pricing and behavioral customer segmentation is the answer.</p>
            <p>When a change is happening, one has to adapt quickly and benefit from it. In recent years the Artificial Intelligence methods gained plenty of momentum. The companies that do not invest in automatization will inevitably lag. However, we still observe aversion to the opportunities posed by techniques such as machine learning, data science, and large-scale data analytics. This will change as the major player use and show the power of the algorithms.</p>
            <p>Properly deployed, Artificial Intelligence increases customer value across all sales channels. It is possible to gain a competitive advantage by offering your clients a personalized experience, cutting down on operational costs, and increasing the margins whenever possible. In this article, we will focus on dynamic pricing and behavioral customer segmentation to transform your company with AI.</p>

            <h2>Dynamic pricing</h2>
            <p>The term “dynamic pricing” is often encountered when one is talking about e-commerce and trading companies. While it gained popularity recently, there exist examples of companies using <URL to="https://edisciplinas.usp.br/pluginfile.php/334207/mod_resource/content/1/Borenstein%2C%20S_Andrea_Shepard%2C%201996_%20Dynamic%20pricing%20in%20Retail%20gasoline%20markets.pdf">dynamic pricing in their everyday business as early as 1996</URL>. Nowadays, dynamic pricing systems are used much more commonly.</p>
            <p>When one buys a car from a dealer, buys ticket planes, or orders a Lyft/Uber – there are everywhere, and we do not notice them. To observe how widely spread such systems are, we recommend a small experiment to the reader. Next time you plan a summer break, and you have to order the flight tickets, observe how the price is changing with time. The most exciting thing is that there are tools <URL to="https://www.airhint.com">that try to reverse engineer dynamic pricing systems in the flight ticket industry</URL>.</p>
            <p>So, what exactly is dynamic pricing?<br></p>
            <p>Dynamic pricing is a strategy of setting the price of an item into account for massive market signals. Such signals include the demand, the liquidity of the market, the type of the customer, customer's behavior, and many more. Each system we have seen uses a different subset of such signals, depending on the field of the business and what is available in the data warehouse.</p>
            <p>The goal is to create an optimal selling strategy. Often it simply means increasing the margin. However, for many businesses, there are other KPIs. For example, instead of maximizing the margin from a single transaction, it is often vital to build a lasting relationship with large customers. The system should be able to detect that and offer a price reduction then. In some fields, the transaction's timing plays a role – a reseller might want to reject the transaction if he does not own the item in his warehouse and instead would have to rely on his unreliable providers. Therefore, the "optimal strategy" means different things for different companies.</p>
            <h2>Demand prediction</h2>
            <p>Still, the fundamental tendency is easy to observe: the larger the demand, the more freedom in price setting the seller has. Therefore, in real-time systems, where the transactions are made every minute/hour, it is crucial to predict the demand.</p>
            <p>Let's introduce some machine learning concepts. We will be working with time-series data. The most important information for our system is the history of the demand. The models we build are making use of multiple factors, such as seasonality, aggregated statistics of the customers, macroeconomic indicators, competitor prices, weather, etc.</p>
            <p>The models we create are trained in a supervised way, i.e., they learn from ground truth data. Nowadays, the most commonly used techniques are LSTM deep neural networks, 1D-convolutional deep neural networks, linear models, and ensemble models. What model and what signals to choose depends on the dataset, and we always tailor our solution to what can be observed in the data. Below is an example of a demand prediction model.</p>
            <DoroImage :src="Image1" max-width="839px" square></DoroImage>
            <p>An example of a well-executed demand forecasting is a <URL to="https://www.cio.com/article/3070384/starwood-taps-machine-learning-to-dynamically-price-hotel-rooms.html">system deployed by Starwood Hotels</URL>. Starwood spent over $50 million on the development of a revenue optimization system that the demand forecasting is a part of.</p>

            <h2>Behavioral customer segmentation</h2>
            <p>Another observation is that some of the buyers often do not know how much an item/service is worth and are willing to pay more than they paid. On the other hand, no company would like to overshoot the prices and scare off the more knowledgeable clients.</p>
            <p>Therefore, it is crucial to understand the customer base and personalize the experience. A simple tool that opens a lot of opportunities is behavioral customer segmentation. Like the demand prediction, we use multiple factors, though this time, the factors will be computed per-user.</p>
            <p>In the case of e-commerce web portals, we utilize the history of his clicks, the journey through the portal, his history of purchases, comments, and the types of the products he/she buys. In traditional trading companies, we take a look at the way of communication, history of trades, the client's location, etc.</p>
            <p>For small datasets, we use traditional unsupervised learning methods. The unsupervised flavor is essential here. In contrary to the supervised strategies, we do not want to impose any ground truth – the algorithm should found the segments.</p>
            <p>We start with dimensionality reduction to remove unwanted correlations and construct sophisticated axes of profiling. Then we use hierarchical clustering to create the cluster and freely choose the number of them. If the dataset is larger, we opt for two-stage clustering – we start with hierarchical clustering on a small sample of the data to get the seeds for the K-means.</p>
            <p>We pay great attention to the business interpretation of the clusters – it should be possible to describe each cluster by a representative user, using few adjectives to characterize him/her – for example, "premium brand Christmas buyer". Such a cluster would be combined mostly of users who make irregular purchases, primarily in November/December.</p>
            <p>They would also be characterized by transactions that consist of a single premium brand item. Once we have such a cluster, we can use this knowledge to optimize the pricing strategy, the marketing and the customer experience for users who fall into that segment.</p>

            <h2>What is yet unexplored?<br></h2>
            <p>Many top companies like eBay, Amazon, Otto, Zalando, etc. use the mentioned techniques to boost sales. On the other hand, we believe there is an open field for improvements in the case of B2B business. The technologies are yet to make an impact there.</p>
            <p>New opportunities are also opening in the case of B2C businesses. It is possible to combine the techniques mentioned in this article, and even to extend the impact of Artificial Intelligence by adding a recommender system and analyzing analytically the customer journey.</p>

            <Promo place="Trading and Retail"></Promo>
        </article>
    </div>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';
    import DoroImage from '@/components/DoroImage';
    import Promo from '@/components/Promo';
    import URL from '@/components/URL';

    import ImageMain from '@/assets/articles/main-photo/ai-in-trading-and-retail.png';
    import Image1 from '@/assets/articles/ai-in-trading-and-retail/1.jpg';

    @Component({
        components: {
            DoroImage,
            Promo,
            URL,
        },
        metaInfo() {
            return {
                title: 'Artificial Intelligence in Trading and Retail',
				// link: [
				// 	{ rel: "amphtml", href: "https://airev.us/amp/ai-in-trading-and-retail" },
				// ],
				meta: [
				],
            }
        }
    })
    export default class AiInTradingAndRetail extends Vue {
		get ImageMain() { return ImageMain; }
		get Image1() { return Image1; }
    }
</script>